export const jobList = [
  {
    text: "Job ID",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Company Name",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "No. of trucks",
    sortable: true,
    value: "numberOfAssignmentsRequired",
    class: "table-header-item",
  },

  {
    text: "Move Date",
    sortable: true,
    value: "pickupDate",
    class: "table-header-item",
  },
  {
    text: "From",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "To",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Assignments",
    sortable: false,
    value: "assignments",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Comments",
    sortable: false,
    class: "table-header-item",
  },
];
export const procurementList = [
  {
    text: "Job ID",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workinCountry",
    class: "table-header-item",
  },

  {
    text: "Company Name",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Start Date",
    sortable: true,
    value: "createdDate",
    class: "table-header-item",
  },
  {
    text: "From",
    sortable: false,
    value: "from",
    class: "table-header-item",
  },
  {
    text: "To",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Selling Price",
    sortable: true,
    value: "quote",
    class: "table-header-item",
  },
  {
    text: "Assignments",
    sortable: false,
    value: "assignments",
    class: "table-header-item",
  },
  {
    text: "Status | Executive",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Bid",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Comments",
    sortable: false,
    class: "table-header-item",
  },
];

export const accountAdvanceList = [
  {
    text: "Job Details",
    sortable: false,
    value: "jobid",
    class: "table-header-item",
  },
  {
    text: "Assign. ID/Status",
    sortable: false,
    value: "id",
    class: "table-header-item",
  },

  {
    text: "Customer Details",
    sortable: false,
    value: "customerDetail",
    class: "table-header-item",
  },
  {
    text: "Driver Details",
    sortable: false,
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Transporter Details",
    sortable: false,
    value: "transDetails",
    class: "table-header-item",
  },
  {
    text: "Truck Details",
    sortable: false,
    value: "truckDetails",
    class: "table-header-item",
  },
  {
    text: "Duty/Assign. Price",
    sortable: false,
    value: "driverPrice",
    class: "table-header-item",
  },
  {
    text: "Driver Price",
    sortable: false,
    value: "driverPrice",
    class: "table-header-item",
  },
  {
    text: "Invoice Amount(Assign. Level)",
    sortable: false,
    value: "driverPrice",
    class: "table-header-item",
  },
  {
    text: "Adv. Requested",
    sortable: false,
    value: "advanceRequested",
    class: "table-header-item",
  },
  {
    text: "Adv. Paid",
    sortable: false,
    value: "advancePaid",
    class: "table-header-item",
  },

  {
    text: "Additional Charges",
    sortable: false,
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Final Payment To Be Paid",
    sortable: false,
    value: "finalPay",
    class: "table-header-item",
  },
  {
    text: "Purchase Invoice",
    sortable: false,
    value: "comments",
    class: "table-header-item",
  },
  {
    text: "Sales Invoice",
    sortable: false,
    value: "comments",
    class: "table-header-item",
  },
  {
    text: " Internal Comments",
    sortable: false,
    value: "comments",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];

export const accountCustomerPayment = [
  {
    text: "Sales Invoice No.",
    sortable: false,
    value: "jobid",
    class: "table-header-item",
  },
  {
    text: "Company Name",
    sortable: false,
    value: "id",
    class: "table-header-item",
  },
  {
    text: "Sales Invoice Type",
    sortable: false,
    value: "id",
    class: "table-header-item",
  },
  {
    text: "Invoice Raised Date",
    sortable: false,
    value: "customerDetail",
    class: "table-header-item",
  },

  {
    text: "Total Sales Amount",
    sortable: false,
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Adjusted Amount",
    sortable: false,
    value: "transDetails",
    class: "table-header-item",
  },
  {
    text: "Outstanding Amount",
    sortable: false,
    value: "truckDetails",
    class: "table-header-item",
  },
  {
    text: "Loss To Trukkin",
    sortable: false,
    value: "driverPrice",
    class: "table-header-item",
  },
];
export const adjustCustomerPayment = [
  {
    text: "Company Name",
    width: "200px",
    sortable: false,
    value: "jobid",
    class: "table-header-item",
  },
  {
    text: "Date",
    width: "200px",
    sortable: false,
    value: "id",
    class: "table-header-item",
  },

  {
    text: "Receipt Number",
    sortable: false,
    value: "customerDetail",
    class: "table-header-item",
  },
  {
    text: "Cash / Cheque ",
    sortable: false,
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Received Amount",
    sortable: false,
    value: "transDetails",
    class: "table-header-item",
  },
  {
    text: "Adjusted  Amount",
    sortable: false,
    value: "truckDetails",
    class: "table-header-item",
  },
  {
    text: "Balance Amount ",
    sortable: false,
    value: "driverPrice",
    class: "table-header-item",
  },
];

export const markedOffCustomerPayment = [
  {
    text: "Adjusted Date",
    sortable: false,
    value: "jobid",
    class: "table-header-item",
  },
  {
    text: "Invoice Number",
    sortable: false,
    value: "id",
    class: "table-header-item",
  },
  {
    text: "Invoice Type",
    sortable: false,
    value: "id",
    class: "table-header-item",
  },

  {
    text: "Total Invoice Amount",
    sortable: false,
    value: "customerDetail",
    class: "table-header-item",
  },
  {
    text: "Total Pending Amount  ",
    sortable: false,
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Total Adjusted Amount ",
    sortable: false,
    value: "transDetails",
    class: "table-header-item",
  },
  {
    text: "Adjusted By ",
    sortable: false,
    value: "truckDetails",
    class: "table-header-item",
  },
  {
    text: "Action ",
    sortable: false,
    value: "truckDetails",
    class: "table-header-item",
  },
];

export const openmarkedOffCustomerPayment = [
  {
    text: "Receipt Reference No",
    sortable: false,
    value: "jobid",
    class: "table-header-item",
  },
  {
    text: "Receipt Date",
    sortable: false,
    value: "id",
    class: "table-header-item",
  },

  {
    text: "Total Receipt Amount",
    sortable: false,
    value: "customerDetail",
    class: "table-header-item",
  },
  {
    text: "Total Balance Amount ",
    sortable: false,
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: " Total Adjusted Amount",
    sortable: false,
    value: "transDetails",
    class: "table-header-item",
  },
  {
    text: " Received By",
    sortable: false,
    value: "transDetails",
    class: "table-header-item",
  },
];

export const payList = [
  {
    text: "Amount",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  // {
  //   text: "Last Updated At",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },
  {
    text: "Updated By",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Payment Mode",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  // {
  //   text: "Issued To",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },
  {
    text: "Name, ID, Truck no.",
    sortable: false,
    value: "detail",
    class: "table-header-item",
  },
  {
    text: "Reject Reason",
    sortable: false,
    value: "reason",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];

export const assignmentList = [
  {
    text: "Job Details",
    sortable: false,
    value: "jobid",
    class: "table-header-item",
  },
  // {
  //   text: "Assign ID",
  //   sortable: false,
  //   value: "id",
  //   class: "table-header-item",
  // },
  {
    text: "Assign. ID/Status",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
  // {
  //   text: "Job Date",
  //   sortable: false,
  //   value: "jobdate",
  //   class: "table-header-item",
  // },
  {
    text: "Customer Details",
    sortable: false,
    value: "customerDetail",
    class: "table-header-item",
  },
  {
    text: "Driver Details",
    sortable: false,
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Transporter Details",
    sortable: false,
    value: "transDetails",
    class: "table-header-item",
  },
  {
    text: "Truck Details",
    sortable: false,
    value: "truckDetails",
    class: "table-header-item",
  },
  {
    text: "Driver Price",
    sortable: false,
    value: "driverPrice",
    class: "table-header-item",
  },
  {
    text: "Adv. Requested",
    sortable: false,
    value: "advanceRequested",
    class: "table-header-item",
  },
  {
    text: "Adv. Paid",
    sortable: false,
    value: "advancePaid",
    class: "table-header-item",
  },

  {
    text: "Additional Charges",
    sortable: false,
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Final Payment To Be Paid",
    sortable: false,
    value: "finalPay",
    class: "table-header-item",
  },
  {
    text: "Comments",
    sortable: false,
    value: "comments",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const finalPayList = [
  {
    text: "Amount",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  {
    text: "Final Payment Date & time",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Actual Paid Date & time",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Driver Details",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },

  {
    text: "Debtor Details",
    sortable: false,
    value: "detail",
    class: "table-header-item",
  },
  {
    text: "Name, ID, Truck no.",
    sortable: false,
    value: "detail",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "reason",
    class: "table-header-item",
  },
  {
    text: "Comments",
    sortable: false,
    value: "reason",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const finalPayListTransporter = [
  {
    text: "Amount",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  {
    text: "Final Payment Date & time",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Actual Paid Date & time",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },

  {
    text: "Debtor Details",
    sortable: false,
    value: "detail",
    class: "table-header-item",
  },
  {
    text: "Name, ID, Truck no.",
    sortable: false,
    value: "detail",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "reason",
    class: "table-header-item",
  },
  {
    text: "Comments",
    sortable: false,
    value: "reason",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const salesList = [
  {
    text: "Job ID",
    sortable: false,
    value: "jobid",
    class: "table-header-item",
  },
  {
    text: "Inquiry ID",
    sortable: false,
    value: "id",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Job Type",
    sortable: false,
    value: "jobType",
    class: "table-header-item",
  },

  {
    text: "Customer Details",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },
  {
    text: "Start Date",
    sortable: true,
    value: "source",
    class: "table-header-item",
  },
  {
    text: "Address",
    sortable: false,
    value: "destination",
    class: "table-header-item",
  },
  {
    text: "Trucks",
    sortable: true,
    value: "trucks",
    class: "table-header-item",
  },
  {
    text: "Invoice No.",
    sortable: false,
    value: "invoice",
    class: "table-header-item",
  },
  {
    text: "Target Price | Inquiry Source",
    sortable: true,
    value: "quote",
    class: "table-header-item",
  },
  {
    text: "Quote needed by Date/Time",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },
  {
    text: "Status",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },
  {
    text: "Assignments",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },

  {
    text: "Actions",
    sortable: false,
    class: "table-header-item",
    value: "actions",
  },
];
export const salesListPak = [
  {
    text: "Job ID",
    sortable: false,
    value: "jobid",
    class: "table-header-item",
  },
  {
    text: "Inquiry ID",
    sortable: false,
    value: "id",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Job Type",
    sortable: false,
    value: "jobType",
    class: "table-header-item",
  },

  {
    text: "Customer Details",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },
  {
    text: "Start Date",
    sortable: true,
    value: "source",
    class: "table-header-item",
  },
  {
    text: "Address",
    sortable: false,
    value: "destination",
    class: "table-header-item",
  },
  {
    text: "Trucks",
    sortable: true,
    value: "trucks",
    class: "table-header-item",
  },
  //  {
  //   text: "Invoice No.",
  //   sortable: false,
  //   value: "invoice",
  //   class: "table-header-item",
  // },
  {
    text: "Quotation | Inquiry Source",
    sortable: true,
    value: "quote",
    class: "table-header-item",
  },
  {
    text: "Quote needed by Date/Time",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },
  {
    text: "Status",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },
  {
    text: "Assignments",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },

  {
    text: "Actions",
    sortable: false,
    class: "table-header-item",
    value: "actions",
  },
];
export const tenderList = [
  {
    text: "Job ID",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
  {
    text: "Source",
    sortable: false,
    value: "source",
    class: "table-header-item",
  },

  {
    text: "Destination",
    sortable: false,
    value: "destination",
    class: "table-header-item",
  },
  {
    text: "Truck Type",
    sortable: false,
    value: "TruckType",
    class: "table-header-item",
  },
  {
    text: "Truck Req.",
    sortable: true,
    value: "truckReq",
    class: "table-header-item",
  },
  {
    text: "Remaining Quantity",
    sortable: true,
    value: "RemainQuantity",
    class: "table-header-item",
  },
  {
    text: "Start Price",
    sortable: true,
    class: "table-header-item",
    value: "startPrice",
  },
  {
    text: "Loading Time & Date",
    sortable: true,
    class: "table-header-item",
    value: "Loading",
  },
  {
    text: "Closed Time & Date",
    sortable: true,
    class: "table-header-item",
    value: "Closed",
  },
  {
    text: "Action",
    sortable: false,
    class: "table-header-item",
    value: "Action",
  },
];
export const requestcallbackList = [
  {
    text: "Customer ID",
    sortable: true,
    value: "customerId",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Customer Details",
    sortable: true,
    class: "table-header-item",
    value: "customer-details",
  },
  {
    text: "Creation Date & Purpose",
    sortable: true,
    value: "created-date",
    class: "table-header-item",
  },
  {
    text: "Booking ID",
    sortable: false,
    value: "bid",
    class: "table-header-item",
  },
  {
    text: "Request ID & Status",
    sortable: true,
    value: "rid",
    class: "table-header-item",
  },
  {
    text: "Comments",
    sortable: false,
    value: "comments",
    class: "table-header-item",
  },

  {
    text: "Executive",
    sortable: true,
    class: "table-header-item",
    value: "executive",
  },
  {
    text: "Actions",
    sortable: false,
    class: "table-header-item",
    value: "actions",
  },
];
export const driverList = [
  {
    text: "Driver ID",
    sortable: false,
    value: "driverId",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Driver Type",
    sortable: false,
    value: "jobtype",
    class: "table-header-item",
  },
  {
    text: "Driver Name",
    sortable: true,
    value: "name",
    class: "table-header-item",
  },
  {
    text: "Truck/Flight/Container Details",
    sortable: false,
    value: "truck number",
    class: "table-header-item",
  },
  // {
  //   text: "Truck Type",
  //   sortable: false,
  //   value: "truckType",
  //   class: "table-header-item",
  // },
  {
    text: "SignUp Info",
    sortable: false,
    value: "DriverType",
    class: "table-header-item",
  },
  // {
  //   text: "Device Type",
  //   sortable: false,
  //   value: "device",
  //   class: "table-header-item",
  // },
  // {
  //   text: "Phone Number",
  //   sortable: false,
  //   value: "phoneNumber",
  //   class: "table-header-item",
  // },
  {
    text: "Availability",
    sortable: false,
    value: "availaibility",
    class: "table-header-item",
  },

  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },

  {
    text: "Actions",
    sortable: false,
    class: "table-header-item",
  },
];
export const driverassignmentListTrans = [
  {
    text: "Job ID",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Assignment ID",
    sortable: false,
    value: "assignmentId",
    class: "table-header-item",
  },
  {
    text: "Assigned As",
    sortable: false,
    value: "ownerType",
    class: "table-header-item",
  },
  // {
  //   text: "Start Date",
  //   sortable: false,
  //   value: "startDate",
  //   class: "table-header-item",
  // },
  {
    text: "From",
    sortable: false,
    width: "200px",
    value: "from",
    class: "table-header-item",
  },
  {
    text: "To",
    sortable: false,
    width: "200px",
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Truck Detail",
    sortable: false,
    width: "100px",
    value: "Truck Nu",
    class: "table-header-item",
  },
  {
    text: "Driver price",
    sortable: false,
    width: "100px",
    value: "paymentAmount",
    class: "table-header-item",
  },
  // {
  //   text: "Additional Charges",
  //   sortable: false,
  //   width: "100px",
  //   value: "paymentAmount",
  //   class: "table-header-item",
  // },
  // {
  //   text: "Advance Amount",
  //   sortable: false,
  //   width: "100px",
  //   value: "paymentAmount",
  //   class: "table-header-item",
  // },

  // {
  //   text: "Status",
  //   sortable: false,
  //   width: "50px",
  //   value: "status",
  //   class: "table-header-item",
  // },
  // {
  //   text: "Earnings",
  //   sortable: false,
  //   width: "50px",
  //   value: "earnings",
  //   class: "table-header-item",
  // },
  // {
  //   text: "Outstanding Payment",
  //   sortable: false,
  //   width: "50px",
  //   value: "outstandingPay",
  //   class: "table-header-item",
  // },
  {
    text: "Waybill",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
  // {
  //   text: "Payment",
  //   sortable: false,
  //   class: "table-header-item",
  // },
  {
    text: "Proof of delivery",
    sortable: false,
    class: "table-header-item",
  },

  {
    text: "Details",
    sortable: false,
    class: "table-header-item",
  },
];
export const driverassignmentList = [
  {
    text: "Job ID",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Assignment ID",
    sortable: false,
    value: "assignmentId",
    class: "table-header-item",
  },
  {
    text: "Assigned As",
    sortable: false,
    value: "ownerType",
    class: "table-header-item",
  },
  // {
  //   text: "Start Date",
  //   sortable: false,
  //   value: "startDate",
  //   class: "table-header-item",
  // },
  {
    text: "From",
    sortable: false,
    width: "200px",
    value: "from",
    class: "table-header-item",
  },
  {
    text: "To",
    sortable: false,
    width: "200px",
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Truck Detail ",
    sortable: false,
    width: "100px",
    value: "Truck Nu",
    class: "table-header-item",
  },
  {
    text: "Driver price",
    sortable: false,
    width: "100px",
    value: "paymentAmount",
    class: "table-header-item",
  },
  {
    text: "Additional Charges",
    sortable: false,
    width: "100px",
    value: "paymentAmount",
    class: "table-header-item",
  },
  {
    text: "Advance Paid",
    sortable: false,
    width: "100px",
    value: "paymentAmount",
    class: "table-header-item",
  },

  // {
  //   text: "Status",
  //   sortable: false,
  //   width: "50px",
  //   value: "status",
  //   class: "table-header-item",
  // },
  // {
  //   text: "Earnings",
  //   sortable: false,
  //   width: "50px",
  //   value: "earnings",
  //   class: "table-header-item",
  // },
  // {
  //   text: "Outstanding Payment",
  //   sortable: false,
  //   width: "50px",
  //   value: "outstandingPay",
  //   class: "table-header-item",
  // },
  {
    text: "Waybill",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
  // {
  //   text: "Payment",
  //   sortable: false,
  //   class: "table-header-item",
  // },
  {
    text: "Proof of delivery",
    sortable: false,
    class: "table-header-item",
  },

  {
    text: "Details",
    sortable: false,
    class: "table-header-item",
  },
];
export const transporterList = [
  {
    text: "Transporter ID",
    sortable: false,
    value: "tranporterId",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Registered As",
    sortable: false,
    value: "category",
    class: "table-header-item",
  },
  {
    text: "Transporter Type",
    sortable: false,
    value: "Type",
    class: "table-header-item",
  },
  {
    text: "Company Name",
    sortable: true,
    value: "companyName",
    class: "table-header-item",
  },
  {
    text: "Registered On",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Device | Signup Type",
    sortable: false,
    value: "device",
    class: "table-header-item",
  },
  {
    text: "Username",
    sortable: false,
    value: "transporterName",
    class: "table-header-item",
  },
  {
    text: "Phone Number",
    sortable: false,
    value: "phonNo",
    class: "table-header-item",
  },
  {
    text: "Email",
    sortable: false,
    value: "email",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const transporterDriverList = [
  {
    text: "Driver ID",
    sortable: false,
    value: "driverId",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Driver Type",
    sortable: false,
    value: "jobtype",
    class: "table-header-item",
  },
  {
    text: "Driver Name",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },
  {
    text: "Truck/Flight/Container Number",
    sortable: false,
    value: "truck number",
    class: "table-header-item",
  },
  {
    text: "Truck Type",
    sortable: false,
    value: "truckType",
    class: "table-header-item",
  },
  {
    text: "Registered As",
    sortable: false,
    value: "DriverType",
    class: "table-header-item",
  },
  {
    text: "Device Type",
    sortable: false,
    value: "device",
    class: "table-header-item",
  },
  {
    text: "Phone Number",
    sortable: false,
    value: "phoneNumber",
    class: "table-header-item",
  },
  {
    text: "Availability",
    sortable: false,
    value: "availaibility",
    class: "table-header-item",
  },

  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
  {
    text: "Actions",
    sortable: false,
    class: "table-header-item",
  },
];
export const transporterDriverEarningList = [
  {
    text: "Job ID",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Assignment ID",
    sortable: false,
    value: "assignmentId",
    class: "table-header-item",
  },
  {
    text: "Start Date",
    sortable: false,
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Pickup Address",
    sortable: false,
    width: "200px",
    value: "from",
    class: "table-header-item",
  },
  {
    text: "Drop off Address",
    sortable: false,
    width: "200px",
    value: "from",
    class: "table-header-item",
  },
  {
    text: "Driver Price",
    sortable: false,
    width: "200px",
    value: "driverCharges",
    class: "table-header-item",
  },
  {
    text: "Additional Charges",
    sortable: false,
    width: "100px",
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Advance Paid",
    sortable: false,
    width: "50px",
    value: "status",
    class: "table-header-item",
  },
  {
    text: "Outstanding Payment",
    sortable: false,
    value: "paymenr",
    class: "table-header-item",
  },
];
export const userList = [
  {
    text: "Employee ID",
    sortable: false,
    value: "employeeID",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "country",
    class: "table-header-item",
  },

  {
    text: "User Name",
    sortable: true,
    class: "table-header-item",
    value: "user-type",
  },

  {
    text: "Phone No",
    sortable: false,
    class: "table-header-item",
    value: "phone-no",
  },
  {
    text: "Country",
    sortable: false,
    class: "table-header-item",
    value: "country",
  },
  {
    text: "Status",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },
  {
    text: "User Type",
    sortable: false,
    class: "table-header-item",
    value: "user-type",
  },
  {
    text: "Designation",
    sortable: false,
    class: "table-header-item",
    value: "designation",
  },
  {
    text: "Actions",
    sortable: false,
    value: "actions",
    class: "table-header-item",
  },
];
export const rolesList = [
  {
    text: "Sr No",
    sortable: false,
    value: "srno",
    class: "table-header-item",
  },
  {
    text: "User Type",
    sortable: false,
    class: "table-header-item",
    value: "user-type",
  },
  {
    text: "Status",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },
  {
    text: "Actions",
    sortable: false,
    value: "actions",
    class: "table-header-item",
  },
];
export const shipmentDocList = [
  {
    text: "Shipment Type",
    sortable: false,
    value: "Name",
    class: "table-header-item",
  },
  {
    text: "Job Type",
    sortable: false,
    value: "type",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "country",
    class: "table-header-item",
  },
  {
    text: "Geography",
    sortable: false,
    value: "geography",
    class: "table-header-item",
  },
  {
    text: "Booking Type",
    sortable: false,
    value: "bookingType",
    class: "table-header-item",
  },
  {
    text: "Job Mode",
    sortable: false,
    value: "jobMode",
    class: "table-header-item",
  },
  {
    text: "Stakeholder",
    sortable: false,
    value: "stakeholder",
    class: "table-header-item",
  },
  {
    text: "Created on",
    sortable: false,
    value: "createdon",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const customerListVerified = [
  {
    text: "Customer ID",
    sortable: false,
    value: "customerId",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "customerId",
    class: "table-header-item",
  },
  {
    text: "Company Name",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },

  {
    text: "Registered On",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Device | Signup Type",
    sortable: false,
    value: "device",
    class: "table-header-item",
  },
  {
    text: "Executive",
    sortable: false,
    value: "executive",
    class: "table-header-item",
  },

  {
    text: "Bookings  | Inquiries",
    sortable: false,
    class: "table-header-item",
    value: "noOfBookings",
  },
  {
    text: "IB Access",
    sortable: false,
    class: "table-header-item",
    value: "noOfBookings",
  },

  {
    text: "OTP Status",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },

  {
    text: "Actions",
    sortable: false,
    class: "table-header-item",
    value: "actions",
  },
];
export const customerList = [
  {
    text: "Customer ID",
    sortable: false,
    value: "customerId",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "customerId",
    class: "table-header-item",
  },
  {
    text: "Company Name",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },

  {
    text: "Registered On",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Device | Signup Type",
    sortable: false,
    value: "device",
    class: "table-header-item",
  },
  {
    text: "Executive",
    sortable: false,
    value: "executive",
    class: "table-header-item",
  },

  {
    text: "Bookings  | Inquiries",
    sortable: false,
    class: "table-header-item",
    value: "noOfBookings",
  },

  {
    text: "OTP Status",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },
  {
    text: "Status",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },
  {
    text: "Actions",
    sortable: false,
    class: "table-header-item",
    value: "actions",
  },
];
export const customerJobList = [
  {
    text: "Job ID",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Customer Name",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "No. of trucks",
    sortable: false,
    value: "createdDate",
    class: "table-header-item",
  },

  {
    text: "Move Date",
    sortable: false,
    value: "from",
    class: "table-header-item",
  },
  {
    text: "From",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "To",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Assignments",
    sortable: false,
    value: "assignments",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    class: "table-header-item",
  },
];
export const customerSalesList = [
  {
    text: "Job ID",
    sortable: false,
    value: "jobid",
    class: "table-header-item",
  },
  {
    text: "Inquiry ID",
    sortable: false,
    value: "id",
    class: "table-header-item",
  },
  {
    text: "Job Type",
    sortable: false,
    value: "jobType",
    class: "table-header-item",
  },

  {
    text: "Customer Details",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },
  {
    text: "Start Date",
    sortable: false,
    value: "source",
    class: "table-header-item",
  },
  {
    text: "Address",
    sortable: false,
    value: "destination",
    class: "table-header-item",
  },
  {
    text: "Trucks",
    sortable: false,
    value: "trucks",
    class: "table-header-item",
  },
  {
    text: "Quotation | Inquiry Source",
    sortable: false,
    value: "quote",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },

  {
    text: "Actions",
    sortable: false,
    class: "table-header-item",
    value: "actions",
  },
];
export const milestoneList = [
  {
    text: "Sr No",
    sortable: false,
    value: "srno",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Pick Up Location",
    sortable: false,
    class: "table-header-item",
    value: "pickup-location",
  },
  {
    text: "Drop Off Location",
    sortable: false,
    class: "table-header-item",
    value: "dropoff-location",
  },
  {
    text: "Check List",
    sortable: false,
    value: "checklist",
    class: "table-header-item",
  },
  {
    text: "Actions",
    sortable: false,
    value: "actions",
    class: "table-header-item",
  },
];
export const additionalChargeNameList = [
  {
    text: "Sr No",
    sortable: false,
    value: "srno",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Source Country",
    sortable: false,
    class: "table-header-item",
    value: "pickup-location",
  },
  {
    text: "Destination Country",
    sortable: false,
    class: "table-header-item",
    value: "dropoff-location",
  },
  {
    text: "Additional Charge Name",
    sortable: false,
    value: "checklist",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "checklist",
    class: "table-header-item",
  },
  {
    text: "Actions",
    sortable: false,
    value: "actions",
    class: "table-header-item",
  },
];
export const dubaiDashboardList = [
  {
    text: "Destination",
    value: "destination",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Request",
    value: "request",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Confirmed",
    value: "confirmed",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Pending",
    value: "pending",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Cancelled",
    value: "cancel",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Total",
    value: "total",
    sortable: true,
    class: "table-header-item",
  },
];
export const KSADashboardList = [
  {
    text: "Destination",
    value: "destination",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Request",
    value: "request",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Confirmed",
    value: "confirmed",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Pending",
    value: "pending",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Cancelled",
    value: "cancel",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Total",
    value: "total",
    sortable: true,
    class: "table-header-item",
  },
];
export const KARDashboardList = [
  {
    text: "Destination",
    value: "destination",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Destination",
    value: "destination",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Request",
    value: "request",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Confirmed",
    value: "confirmed",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Pending",
    value: "pending",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Cancelled",
    value: "cancel",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Total",
    value: "total",
    sortable: true,
    class: "table-header-item",
  },
];
export const LHRDashboardList = [
  {
    text: "Destination",
    value: "destination",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Request",
    value: "request",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Confirmed",
    value: "confirmed",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Pending",
    value: "pending",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Cancelled",
    value: "cancel",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Total",
    value: "total",
    sortable: true,
    class: "table-header-item",
  },
];
export const approvalList = [
  {
    text: "ID",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Company Name",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },
  {
    text: "No. of Trucks",
    sortable: true,
    value: "trucks",
    class: "table-header-item",
  },

  {
    text: "Move Date",
    sortable: true,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "From",
    sortable: false,
    value: "from",
    class: "table-header-item",
  },
  {
    text: "To",
    sortable: false,
    value: "To",
    class: "table-header-item",
  },
  {
    text: "Selling price/Target",
    sortable: true,
    class: "table-header-item",
    value: "status",
  },

  {
    text: "Status",
    sortable: false,
    class: "table-header-item",
    value: "status",
  },
  {
    text: "Actions",
    sortable: false,
    class: "table-header-item",
    value: "actions",
  },
];
export const accountList = [
  {
    text: "Job Id",
    value: "Job Id",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Working Country",
    value: "workingCountry",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Company Name",
    value: "customer",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Customer Rate",
    value: "jobPrice",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Job Status",
    value: "jobStatus",
    sortable: false,
    class: "table-header-item",
  },

  {
    text: "Job Price Type",
    value: "jobPriceType",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Additional Charges",
    value: "additionalCharges",
    sortable: true,
    class: "table-header-item",
  },
  {
    text: "Driver Rate",
    value: "driverRate",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "PO/PI",
    value: "PO/PI",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Sales Order",
    value: "salesOrdes",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Sales Invoice",
    value: "salesInvoice",
    sortable: false,
    class: "table-header-item",
  },
];
export const accountAssignmentList = [
  {
    text: "Assignment Id",
    value: "id",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Assignment status",
    value: "status",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Driver Details",
    value: "driverDetails",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Duty/Assign. Price ",
    value: "driverDetails",
    sortable: false,
    class: "table-header-item",
  },

  {
    text: "Driver Price",
    value: "driverPrice",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Additional Charges",
    value: "additionalCharges",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Advance Requested",
    value: "advance",
    sortable: false,
    class: "table-header-item",
  },

  {
    text: "Advance Paid",
    value: "advanceAmount",
    sortable: false,
    class: "table-header-item",
  },
  // {
  //   text: "Driver Doc Staus",
  //   value: "driverDocStatus",
  //   sortable: false,
  //   class: "table-header-item",
  // },
  {
    text: "Purchase Order",
    value: "purchaseOrder",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Purchase Invoice",
    value: "purchaseInvoice",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Sales Invoice",
    value: "purchaseInvoice",
    sortable: false,
    class: "table-header-item",
  },
  // {
  //   text: "Pending Amount",
  //   value: "pendingAmount",
  //   sortable: false,
  //   class: "table-header-item",
  // },
  {
    text: "Transporter Details",
    value: "transporterDetails",
    sortable: false,
    class: "table-header-item",
  },
];
export const imeiList = [
  {
    text: "GPS devices number",
    sortable: true,
    value: "ImeiDevice",
    class: "table-header-item",
  },
  {
    text: "Working Country",
    sortable: false,
    value: "workingCountry",
    class: "table-header-item",
  },
  {
    text: "Device status",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
  {
    text: "Job Id",
    sortable: true,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Assignment ID",
    sortable: true,
    value: "assignId",
    class: "table-header-item",
  },
  {
    text: "Assignment Start Date",
    sortable: true,
    value: "startDate",
    class: "table-header-item",
  },

  {
    text: "Driver Name",
    sortable: true,
    value: "drivername",
    class: "table-header-item",
  },

  {
    text: "Added on",
    sortable: true,
    value: "updatedon",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    class: "table-header-item",
  },
];
export const jobDetailAssignmentList = [
  {
    text: "ID",
    value: "assid",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Asset Status",
    value: "assetStatus",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "GPS Device",
    value: "gps",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Duty",
    value: "gps",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Transporter Details",
    value: "transporterDetail",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Status",
    value: "status",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Driver Assignment",
    value: "driverDetail",
    sortable: false,
    class: "table-header-item",
  },

  {
    text: "Truck/Container/Flight No.",
    value: "truckNumber",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Waybill",
    value: "waybill",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Documents",
    value: "documents",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Advance",
    value: "adanvce",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Additional Charges",
    value: "additionalCharges",
    sortable: false,
    class: "table-header-item",
  },
];
export const newAssignOwnerDriverList = [
  {
    text: "Profile picture",
    sortable: false,
    value: "tranporterId",
    class: "table-header-item",
  },
  {
    text: "Driver Details",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },
  {
    text: "Mobile Number",
    sortable: false,
    value: "contact",
    class: "table-header-item",
  },
  {
    text: "Total Bookings",
    sortable: false,
    value: "booking",
    class: "table-header-item",
  },
  {
    text: "Last Location",
    sortable: false,
    value: "booking",
    class: "table-header-item",
  },
  {
    text: "Truck Number",
    sortable: false,
    value: "truckNumber",
    class: "table-header-item",
  },
  {
    text: "Member Since",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const newAssignTransporterList = [
  {
    text: "Profile picture",
    sortable: false,
    value: "tranporterId",
    class: "table-header-item",
  },
  {
    text: "Details",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },
  {
    text: "Mobile Number",
    sortable: false,
    value: "contact",
    class: "table-header-item",
  },
  {
    text: "Total Bookings",
    sortable: false,
    value: "booking",
    class: "table-header-item",
  },
  {
    text: "Total Driver",
    sortable: false,
    value: "driver",
    class: "table-header-item",
  },
  {
    text: "Member Since",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const newAssignTransporterDriverList = [
  {
    text: "Profile picture",
    sortable: false,
    value: "tranporterId",
    class: "table-header-item",
  },
  {
    text: "Driver Details",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },
  {
    text: "Mobile Number",
    sortable: false,
    value: "contact",
    class: "table-header-item",
  },
  {
    text: "Transporter Name",
    sortable: false,
    value: "contact",
    class: "table-header-item",
  },
  {
    text: "Total Bookings",
    sortable: false,
    value: "booking",
    class: "table-header-item",
  },
  {
    text: "Last Location",
    sortable: false,
    value: "booking",
    class: "table-header-item",
  },
  {
    text: "Truck Number",
    sortable: false,
    value: "truckNumber",
    class: "table-header-item",
  },
  {
    text: "Member Since",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const newAssignViewBidList = [
  {
    text: "Driver Id",
    sortable: false,
    value: "driverId",
    class: "table-header-item",
  },
  {
    text: "Driver Name",
    sortable: false,
    value: "driverId",
    class: "table-header-item",
  },

  {
    text: "Bid Amount",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Date and Time",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },

  {
    text: "Advance Amount",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
];
export const additionalChargesList = [
  {
    text: "Price Type",
    sortable: false,
    value: "chargesType",
    class: "table-header-item",
  },
  {
    text: "Customer Price",
    sortable: false,
    value: "costMargin",
    class: "table-header-item",
  },
  {
    text: "Driver Charges",
    sortable: false,
    value: "driverCharges",
    class: "table-header-item",
  },
  {
    text: "Miscellaneous Charges",
    sortable: false,
    value: "driverCharges",
    class: "table-header-item",
  },

  {
    text: "Description",
    sortable: false,
    value: "Type",
    class: "table-header-item",
  },
  {
    text: "Comment",
    sortable: false,
    value: "Type",
    class: "table-header-item",
  },
  {
    text: "Date & Time",
    sortable: false,
    value: "dateTime",
    class: "table-header-item",
  },
  {
    text: "Added By",
    sortable: false,
    value: "addedBy",
    class: "table-header-item",
  },
  {
    text: "Proof",
    sortable: false,
    value: "proof",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];

export const assignmentMultiplePayment = [
  {
    text: "Assign. Id",
    sortable: false,
    value: "chargesType",
    class: "table-header-item",
  },
  {
    text: "Total Driver Price",
    sortable: false,
    value: "chargesType",
    class: "table-header-item",
  },
  {
    text: "Amount Paid Till Date",
    sortable: false,
    value: "chargesType",
    class: "table-header-item",
  },

  {
    text: "Amount To be Paid",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  {
    text: "Upload Proofs",
    sortable: false,
    value: "Type",
    class: "table-header-item",
  },
];
export const shipmentsDocList = [
  {
    text: "Shipment Type",
    sortable: false,
    value: "docName",
    class: "table-header-item",
  },
  {
    text: "Uploaded By",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  {
    text: "Document Attached",
    sortable: false,
    value: "document",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const proofOfDeliveryList = [
  {
    text: "Description",
    sortable: false,
    value: "Type",
    class: "table-header-item",
  },
  {
    text: "Date & Time",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Uploaded By",
    sortable: false,
    value: "document",
    class: "table-header-item",
  },
  {
    text: "Document",
    sortable: false,
    value: "document",
    class: "table-header-item",
  },
  {
    text: "Rejection Reason",
    sortable: false,
    value: "rejectReason",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const proofOfDeliveryListDriver = [
  {
    text: "Description",
    sortable: false,
    value: "Type",
    class: "table-header-item",
  },
  {
    text: "Date & Time",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Uploaded By",
    sortable: false,
    value: "document",
    class: "table-header-item",
  },
  {
    text: "Document",
    sortable: false,
    value: "document",
    class: "table-header-item",
  },
  // {
  //   text: "Rejection Reason",
  //   sortable: false,
  //   value: "rejectReason",
  //   class: "table-header-item",
  // },

  // {
  //   text: "Action",
  //   sortable: false,
  //   value: "action",
  //   class: "table-header-item",
  // },
];
export const generateAdvanceAccountsList = [
  {
    text: "Amount Requested",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  {
    text: "Amount Paid",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  // {
  //   text: "Last Updated At",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },
  // {
  //   text: "Updated By",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },
  {
    text: "Driver Payment Details",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },

  // {
  //   text: "Issued To",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },
  // {
  //   text: "Debtor Details",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },
  {
    text: "Actual Paid Date & Time",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Name, ID, Truck no.",
    sortable: false,
    value: "detail",
    class: "table-header-item",
  },
  {
    text: "Advance Status",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Adjust Amount",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },

  {
    text: "Reject Reason",
    sortable: false,
    value: "reason",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const generateAdvanceAccountsListSub = [
  {
    text: "Amount",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  // {
  //   text: "Last Updated At",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },
  {
    text: "Updated By",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  // {
  //   text: "Driver Payment Details",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },

  // {
  //   text: "Issued To",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },
  {
    text: "Mode of Payment",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Actual Paid Date & Time",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "View Proof",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
];
export const generateAdvanceList = [
  {
    text: "Amount Requested",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  {
    text: "Amount Paid",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  // {
  //   text: "Last Updated At",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },
  {
    text: "Updated By",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Driver Payment Details",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Debtor Details",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Actual Paid Date & Time",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  // {
  //   text: "Issued To",
  //   sortable: false,
  //   value: "date",
  //   class: "table-header-item",
  // },
  {
    text: "Name, ID, Truck no.",
    sortable: false,
    value: "detail",
    class: "table-header-item",
  },
  {
    text: "Advance Status",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Adjust Amount",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },

  {
    text: "Cancel/Reject Reason",
    sortable: false,
    value: "reason",
    class: "table-header-item",
  },

  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];

export const customerRecieveList = [
  {
    text: "Actual Amt. Received",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  {
    text: "Balance Amt.",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Adjusted Amt.",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Adjusted On",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: " Received Date & Time",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },

  {
    text: "Amt. Received by Admin",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Amt. Received Through",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Adjusted Invoice Number",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
  {
    text: "Proofs",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },

  {
    text: "Comments",
    sortable: false,
    value: "detail",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },
];

export const jobListAssignmentpopup = [
  {
    text: "Assignment ID",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Type",
    sortable: false,
    value: "jobType",
    class: "table-header-item",
  },

  {
    text: "Truck Type",
    sortable: false,
    value: "jobType",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Driver Details",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Driver Truck Number",
    sortable: false,
    value: "truckNumber",
    class: "table-header-item",
  },
  // {
  //   text: "Action",
  //   sortable: false,
  //   value: "booking",
  //   class: "table-header-item",
  // },
];
export const jobListAssignmentpopupSales = [
  {
    text: "Assignment ID",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Type",
    sortable: false,
    value: "jobType",
    class: "table-header-item",
  },

  {
    text: "Truck Type",
    sortable: false,
    value: "jobType",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },

  // {
  //   text: "Action",
  //   sortable: false,
  //   value: "booking",
  //   class: "table-header-item",
  // },
];
export const sendBidpopupDriverList = [
  {
    text: "Driver Name",
    sortable: false,
    value: "Drivername",
    class: "table-header-item",
  },
  {
    text: "Driver Id",
    sortable: false,
    value: "DriverId",
    class: "table-header-item",
  },
  {
    text: "Total Bookings",
    sortable: false,
    value: "booking",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "booking",
    class: "table-header-item",
  },
];
export const sendBidpopupTransporterList = [
  {
    text: "Transporter Name",
    sortable: false,
    value: "name",
    class: "table-header-item",
  },
  {
    text: "Transporter Id",
    sortable: false,
    value: "transporterId",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "action",
    class: "table-header-item",
  },
];
export const sendBidpopupTransporterResponseList = [
  {
    text: "Transporter Details",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },

  {
    text: "Bid Placed Date & Time",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },

  {
    text: "Applied Bid Amount",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Number of Trucks ",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Amount Per Truck ",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },

  {
    text: "Comments",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  // {
  //   text: "Action",
  //   sortable: false,
  //   value: "action",
  //   class: "table-header-item",
  // },
];
export const sendBidpopupDriverResponseList = [
  {
    text: "Driver Id",
    sortable: false,
    value: "driverId",
    class: "table-header-item",
  },
  {
    text: "Driver Name",
    sortable: false,
    value: "driverId",
    class: "table-header-item",
  },

  {
    text: "Bid Amount",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Date and Time",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Advance Amount",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
];
export const salesAddressList = [
  {
    text: "Sr No.",
    sortable: false,
    value: "index",
    class: "table-header-item",
  },
  {
    text: "Job Type",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Source",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Destination",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
];
export const tenderViewBidList = [
  {
    text: "S.No",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },

  {
    text: "Date & Time",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Company Name ",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Bid Placed ",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },

  {
    text: "Comments",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  // {
  //   text: "Action",
  //   sortable: false,
  //   value: "action",
  //   class: "table-header-item",
  // },
];
export const jobDetailAssignmentViewBidList = [
  {
    text: "Driver Detail",
    sortable: false,
    value: "driverName",
    class: "table-header-item",
  },
  {
    text: "Job Start Date ",
    sortable: false,
    value: "date",
    class: "table-header-item",
  },

  {
    text: "Bid Amount",
    sortable: false,
    value: "amount",
    class: "table-header-item",
  },
  {
    text: "Bid Date ",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },

  {
    text: "Advance Required",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
  {
    text: "Action",
    sortable: false,
    value: "customer",
    class: "table-header-item",
  },
];
export const salesReport = [
  {
    text: "Job Id & Date",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Price",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Customer",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },

  {
    text: "Source ",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Destination",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Invoice Number",
    sortable: false,
    value: "assignments",
    class: "table-header-item",
  },
  {
    text: "Invoice Generated Date",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },

  {
    text: "Vat Amt.",
    sortable: false,
    class: "table-header-item",
  },

  {
    text: "Additional Charges",
    sortable: false,
    class: "table-header-item",
  },
  {
    text: "Total Sales",
    sortable: false,
    class: "table-header-item",
  },
];
export const assignmentFinanicalReport = [
  {
    text: "Inquiry Id",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Job Id & Date",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Assig. Id & Status",
    sortable: false,
    width: "200px",
    value: "assignId",
    class: "table-header-item",
  },
  {
    text: "Job Mode & Type",
    sortable: false,
    width: "200px",
    value: "assignId",
    class: "table-header-item",
  },
  {
    text: "Company/Customer Details",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Sales Executive",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Office",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },

  {
    text: "Industry",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Invoicing Country",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Source",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Destination",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Vehicle No & Truck Type",
    sortable: false,
    width: "200px",
    value: "vehicleNo.",
    class: "table-header-item",
  },

  {
    text: "Driver  Details",
    sortable: false,
    width: "200px",
    value: "driverRate",
    class: "table-header-item",
  },
  {
    text: "Customer Price ",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Driver Price ",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Additional Charges(Exculsive)",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },

  {
    text: "Additional Charges",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Advance Paid",
    sortable: false,
    width: "200px",
    value: "advanceAmountPaid",
    class: "table-header-item",
  },

  {
    text: "Advance Requested",
    sortable: false,
    width: "200px",
    value: "advanceAmount",
    class: "table-header-item",
  },

  {
    text: "Purchase Invoice No. & Date",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },

  {
    text: "Sales Invoice No. & Date ",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },

  {
    text: "VAT On Sales",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },
  {
    text: "VAT On Purchase",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },
  {
    text: "Total Sales",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },

  {
    text: "Total Purchases",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },

  {
    text: "Margin",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },
];
export const assignmentCompleteReport = [
  {
    text: "Inquiry Id",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Job Id & Date",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Created By",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Assig. Id & Status",
    sortable: false,
    width: "200px",
    value: "assignId",
    class: "table-header-item",
  },
  {
    text: "Job Mode & Type",
    sortable: false,
    width: "200px",
    value: "assignId",
    class: "table-header-item",
  },
  {
    text: "International/Domestic",
    sortable: false,
    width: "200px",
    value: "assignId",
    class: "table-header-item",
  },
  {
    text: "Company/Customer Details",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Sales Executive",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Office",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },

  {
    text: "Industry",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Invoicing Country",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Source",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Destination",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "POD Collected Date",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Offloaded Date",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Payment Date",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },

  {
    text: "Vehicle No & Truck Type",
    sortable: false,
    width: "200px",
    value: "vehicleNo.",
    class: "table-header-item",
  },

  {
    text: "Driver  Details",
    sortable: false,
    width: "200px",
    value: "driverRate",
    class: "table-header-item",
  },
  {
    text: "Customer Price ",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Driver Price ",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Additional Charges(Exculsive)",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },

  {
    text: "Additional Charges",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Advance Paid",
    sortable: false,
    width: "200px",
    value: "advanceAmountPaid",
    class: "table-header-item",
  },

  {
    text: "Advance Requested",
    sortable: false,
    width: "200px",
    value: "advanceAmount",
    class: "table-header-item",
  },

  {
    text: "Purchase Invoice No. & Date",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },

  {
    text: "Sales Invoice No. & Date ",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },

  {
    text: "VAT On Sales",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },
  {
    text: "VAT On Purchase",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },
  {
    text: "Total Sales",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },

  {
    text: "Total Purchases",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },
];
export const advancePaymentReport = [
  {
    text: "Job Id & Date",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Assignment Id",
    sortable: false,
    width: "200px",
    value: "assignId",
    class: "table-header-item",
  },
  {
    text: "Assignment Status",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },

  {
    text: "Purchase Invoice",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Purchase Invoice Generated At",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },

  {
    text: "Driver Details",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },
  {
    text: "Vehicle Number",
    sortable: false,
    width: "200px",
    value: "vehicleNo.",
    class: "table-header-item",
  },

  {
    text: "Driver Acceptance Rate",
    sortable: false,
    width: "200px",
    value: "driverRate",
    class: "table-header-item",
  },
  {
    text: "Additional Charges",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Advance Amount Requested",
    sortable: false,
    width: "200px",
    value: "advanceAmount",
    class: "table-header-item",
  },
  {
    text: "Advance Requested At",
    sortable: false,
    width: "200px",
    value: "advanceRequestedAt",
    class: "table-header-item",
  },
  {
    text: "Advance amount Paid",
    sortable: false,
    width: "200px",
    value: "advanceAmountPaid",
    class: "table-header-item",
  },
  {
    text: "Advance Paid At",
    sortable: false,
    width: "200px",
    value: "advanceAmountPaid",
    class: "table-header-item",
  },
  {
    text: "VAT Amount",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },

  {
    text: "Total Purchases",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },
  {
    text: "Pending Payment",
    sortable: false,
    width: "200px",
    value: "vat",
    class: "table-header-item",
  },
];
export const uninvoiceAssignmentReport = [
  {
    text: "Job Id & Create Date",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Start Date",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Status",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Source",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Destination",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Customer",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Assign.Id & Status ",
    sortable: false,
    width: "200px",
    value: "assignId",
    class: "table-header-item",
  },
  {
    text: "International/Domestic",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Job Type & Mode",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Purchase Invoice",
    sortable: false,
    width: "200px",
    value: "vehicleNo.",
    class: "table-header-item",
  },
];
export const uninvoiceJobReport = [
  {
    text: "Job Id & Create Date",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Start Date",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Status",
    sortable: false,

    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Price",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Source",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Destination",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Customer",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
];
export const TransporterDriverPaymentReport = [
  {
    text: "Transporter/Driver",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Customer Price",
    sortable: false,
    width: "200px",
    value: "assignId",
    class: "table-header-item",
  },
  {
    text: "Driver Price",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },

  {
    text: "Additional Charge",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Additional Charge (Exclusive)",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },

  {
    text: "Advance Amount Requested",
    sortable: false,
    width: "200px",
    value: "vehicleNo.",
    class: "table-header-item",
  },
  {
    text: "Advance Amount Paid",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },

  {
    text: "Adjusted Amount",
    sortable: false,
    width: "200px",
    value: "driverRate",
    class: "table-header-item",
  },
  {
    text: "Pending Payment",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "No Of Assignments",
    sortable: false,
    width: "200px",
    value: "advanceAmount",
    class: "table-header-item",
  },
  {
    text: "No Of Completed Assignments ",
    sortable: false,
    width: "200px",
    value: "advanceRequestedAt",
    class: "table-header-item",
  },
];

export const KsaAssignmentReport = [
  {
    text: "Job Details",
    sortable: false,
    width: "200px",
    value: "assignId",
    class: "table-header-item",
  },
  {
    text: "Assignment Id",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },

  {
    text: "Customer Name",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },
  {
    text: "Company Name",
    sortable: false,
    width: "200px",
    value: "startDate",
    class: "table-header-item",
  },

  {
    text: "Driver Name",
    sortable: false,
    width: "200px",
    value: "vehicleNo.",
    class: "table-header-item",
  },
  {
    text: "Assignment Status",
    sortable: false,
    width: "200px",
    value: "driverDetails",
    class: "table-header-item",
  },

  {
    text: "Invoicing Country",
    sortable: false,
    width: "200px",
    value: "driverRate",
    class: "table-header-item",
  },
  {
    text: "Region City",
    sortable: false,
    width: "200px",
    value: "additionalCharges",
    class: "table-header-item",
  },
  {
    text: "Payment Date",
    sortable: false,
    width: "200px",
    value: "advanceAmount",
    class: "table-header-item",
  },
  {
    text: "Offloaded Date ",
    sortable: false,
    width: "200px",
    value: "advanceRequestedAt",
    class: "table-header-item",
  },
  {
    text: "Purchase Invoice",
    sortable: false,
    width: "200px",
    value: "advanceRequestedAt",
    class: "table-header-item",
  },
  {
    text: "Sales Invoice ",
    sortable: false,
    width: "200px",
    value: "advanceRequestedAt",
    class: "table-header-item",
  },
];

export const purchaseReport = [
  {
    text: "Job Id",
    sortable: false,
    width: "200px",
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Mode",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Job Type",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Customer",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },

  {
    text: "Customer Accepted Price",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Source ",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Destination",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Ass. Id",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Status",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Office",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Offloaded Date",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Completed Date",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },

  {
    text: "Driver  Details",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Vehicle Number",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Owner Type",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Sales Executive",
    sortable: false,
    value: "jobId",
    class: "table-header-item",
  },
  {
    text: "Sales Invoice Generated",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Purchase Invoice Details",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },

  {
    text: "Driver Accept Amt.",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },

  {
    text: "Additional Charge",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Adv. Amt. Req.",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Adv. Amt. Paid",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Adv. Paid Date",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "VAT",
    sortable: false,
    value: "to",
    class: "table-header-item",
  },
  {
    text: "Total Purchases",
    sortable: false,
    value: "assignments",
    class: "table-header-item",
  },
  {
    text: "Pending Payment",
    sortable: false,
    value: "status",
    class: "table-header-item",
  },
];
